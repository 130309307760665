/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

:root {
    --option-size: 36px;
}

.ProductAttributeValue {
    &-Color,
    &-String,
    &-Image,
    &-Text {
        margin-inline-end: 16px;
    }

    &-Color {
        box-shadow: none;
        border: 1px solid rgb(212, 212, 212);
    }

    &-Color,
    &-Image-Overlay {
        &::before {
            box-shadow: none;
        }

        &::after {
            height: calc(var(--option-size) / 4);
            inset-inline-start: calc(var(--option-size) / 2 - 1px);
            inset-block-start: calc(var(--option-size) / 3);
            width: calc(var(--option-size) / 4);
            transform-origin: bottom left;
        }

        [dir="rtl"] &::after {
            /* stylelint-disable-next-line csstools/use-logical */
            right: calc(var(--option-size) / 2 - 20px);
            inset-block-start: calc(var(--option-size) / 10);
        }
    }
}
