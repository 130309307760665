/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

/* stylelint-disable declaration-no-important */

:root {
    --popup-padding: 0px;
    --popup-desktop-padding: 24px 32px 16px 32px;
    --popup-content-padding: 24px 32px;
    --popup-desktop-width: 544px;
    --popup-border-radius: 6px;
    --popup-header-height: 88px;
    --popup-mobile-content-padding: 16px;
    --popup-background-color: rgba(0, 0, 0, 0.8);
}

.ProductAttributes-Popup, .ProductSummaryStep, .DealersOfferStep-ProductAttributes {
    .ProductAttributes {
        &-Content {
            background-color: var(--default-secondary-white-color);
        }

        &-AttributeHeader, &-TitleContainer {
            background-color: var(--default-primary-light-grey-color);
            margin-block-end: 1px;
            border-radius: var(--popup-border-radius);
            display: flex;
            flex-direction: column;

            @include desktop {
                padding: var(--popup-content-padding);
            }

            @include mobile {
                padding: var( --popup-mobile-content-padding);
            }
        }

        &-Attributes {
            @include mobile {
                grid-template-columns: 1fr 1fr;
            }
        }

        &-Attributes div:nth-last-child(-n+2) {
            margin: 0;
        }

        &-Group {
            font-family: var(--font-family-sans-pro);
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: var(--default-primary-black-color);
            margin-block-end: 12px;
            text-transform: uppercase;
        }

        &-AttributeLabel, &-ValueLabel {
            font-family: var(--font-family-sans-pro);
            font-size: 14px;
            line-height: 20px;
            color: var(--default-primary-silver-color);

            @include desktop {
                margin-block-end: 16px;
            }

            @include mobile {
                margin-block-end: 12px;
            }
        }

        &-AttributeLabel {
            font-weight: 400;
            list-style-type: none;
            height: unset;

            @include mobile {
                padding-inline-start: 0;
            }
        }

        &-ValueLabel .ProductAttributeValue {
            font-weight: 700;
            margin-block-end: 0;
            height: unset;
            text-align: end;
        }

        &-ValueLabel {
            color: var(--default-primary-silver-color);
            height: unset;
            font-family: var(--font-family-sans-pro);
            font-size: 14px;
            line-height: 20px;

            @include mobile {
                display: flex;
                justify-content: flex-end;
            }
        }

        &-AttributeLabel:nth-last-child(1), &-ValueLabel:last-of-type {
            margin: 0;
        }

        &-TitleContainer {
            flex-direction: row;
            align-items: flex-start;
            margin: 0 !important;
        }

        &-ProductTitle {
            font-family: var(--font-family-sans-pro);
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: var(--default-primary-black-color);

            @include desktop {
                margin-inline-end: 12px;
            }

            @include mobile {
                margin-inline-end: 4px;
            }
        }

        &-ProductTrim {
            font-family: var(--font-family-sans-pro);
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--default-secondary-gold-color);

            @include mobile {
                margin-block-start: 0;
            }
        }

        &-AttributeHeaderPrice {
            text-align: end;
            font-family: var(--font-family-sans-pro);
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: var(--default-primary-blue-color);

            @include desktop {
                margin-block-start: 24px;
            }

            @include mobile {
                margin-block-start: 32px;
            }
        }
    }
}
