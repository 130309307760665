/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.ProductMainViewInsurance {
    &-Button {
        width: calc(100% - 32px);
        margin-block: 0 24px;
        margin-inline: 16px;
        text-transform: capitalize;

        @include desktop {
            width: 268px;
            margin-inline: 32px 0; 
        }

        &_isAdded {
            background-color: var(--button-hover-background);
            border-color: var(--button-hover-border);
            color: var(--color-white);
        }

        .CheckmarkIcon {
            &-Icon {
                margin-block: 0 2px;
                margin-inline: 0 12px;

                path {
                    fill: var(--color-white);
                }
            }
        }
    }

    &-Title {
        margin: 28px 0 24px;
        font-weight: 500;
        color: var(--default-primary-blue-color);
        text-transform: uppercase;
        font-size: 24px;
        line-height: 32px;
    }

    &-Wrapper {
        display: flex;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
        }
    }

    &-ImageWrapper {
        @include desktop {
            width: 45%;

            .pagebuilder-mobile-only {
                display: none;
            }

            .pagebuilder-mobile-hidden {
                border-radius: 8px;
            }
        }

        @include mobile {
            .pagebuilder-mobile-hidden {
                display: none;
            }
        }
    }

    &-BlockWrapper {
        margin: 18px 0 0;

        @include desktop {
            width: 55%;
            margin: 0;
        }
    }

    .Image {
        &-Image {
            position: relative;
            height: initial;
        }
    }

    .GeelyInsurance {
        &-HowItWorks, &-WhatsIncluded {
            padding: 0 16px;

            @include desktop {
                padding-inline-start: 32px;
            }
        }
    }
}
