/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

/* stylelint-disable declaration-no-important */

.ProductAttributes-Popup, .ProductSummaryStep, .DealersOfferStep-ProductAttributes {
    .Popup {
        &-Content {
            background-color: var(--default-secondary-white-color);

            @include desktop {
                height: calc(100vh - 2 * 80px);
                border-radius: var(--popup-border-radius);
            }

            @include mobile {
                border-radius: var(--popup-border-radius) var(--popup-border-radius) 0 0;
                height: calc(100% - var(--header-height) + 4px);
                margin-block-start: var(--header-height);
                background-color: white;
            }
        }

        &-Header {
            border-radius: var(--popup-border-radius);

            @include desktop {
                height: var(--popup-header-height);
            }

            @include mobile {
                height: 50px;
                position: fixed;
                inset-block-start: var(--header-height);
            }
        }

        &-ChildrenWrapper {
            @include desktop {
                width: 37vw;
                min-width: 100%;
                padding: var(--popup-padding);
            }

            @include mobile {
                padding: 0;
                margin-block-start: 50px;
                background-color: transparent
            }

            border-radius: 0 0 var(--popup-border-radius) var(--popup-border-radius);
            min-height: calc(100% - var(--popup-header-height));
            background-color: var(--default-primary-light-grey-color);
        }

        &-HeaderWrapper {
            background-color: var(--default-secondary-white-color);
            border-radius: var(--popup-border-radius) var(--popup-border-radius) 0 0;

            @include desktop {
                padding: 0;
            }

            @include mobile {
                padding: 12px 16px;
            }
        }

        &-Heading {
            font-family: var(--font-family-sans-pro);
            font-weight: 500;
            text-transform: uppercase;
            color: var(--default-primary-black-color);

            @include desktop {
                font-size: 36px;
                line-height: 48px;
            }

            @include mobile {
                font-size: 24px;
                line-height: 32px;
            }
        }

        &-CloseBtn {
            width: 28px;
            height: 28px;
            background-image: $productAttributesPopupCloseIcon;
            cursor: pointer;

            @include desktop {
                inset-inline-end: 0;
            }

            @include mobile {
                inset-inline-end: 16px;
                transform: scale(0.8);
                inset-block-start: 25%;
            }

            svg {
                display: none;
            }
        }
    }

    .ProductAttributes {
        &-ProductHeader {
            display: flex;
            flex-direction: column;
            margin: 0;
            align-items: flex-start;
            border-bottom: 1px solid var(--default-secondary-white-color);
            border-radius: 0 0 var(--popup-border-radius) var(--popup-border-radius);
            background-color: var(--default-primary-light-grey-color);

            .Image {
                width: 100%;
                max-width: unset;
                min-height: 280px;
                height: auto;
            }
        }

        &-ProductTotalAmount {
            display: flex;
            justify-content: space-between;
            border-radius: var(--popup-border-radius);
            margin-block-start: 1px;
            border: none;
            background-color: var(--default-primary-light-grey-color);

            @include desktop {
                padding: var(--popup-content-padding);
                display: flex;
            }

            @include mobile {
                display: none;
            }

            .ProductTotalAmount {
                &-Text {
                    font-weight: 400;
                    font-family: var(--font-family-sans-pro);
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--default-primary-silver-color);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &-ValueContainer {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .ProductTotalAmount {
                        &-Value {
                            font-family: var(--font-family-sans-pro);
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 32px;
                            color: var(--default-primary-blue-color);
                            margin-inline-end: 12px;
                            text-transform: uppercase;
                        }

                        &-SecondaryValue {
                            font-family: var(--font-family-sans-pro);
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: var(--default-primary-black-color);
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}

.ProductAttributes-Popup {
    background-color: var(--popup-background-color);

    @include mobile {
        height: calc(100% - var(--add-to-cart-height) - 30px);
        padding: 0 0 4px 0;
        inset-block-start: 0;
        z-index: 100;
        border-radius: 0;
    }
}
