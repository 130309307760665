/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

$downloadIcon: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 8L10 11.5L13.5 8' stroke='%230E306C' stroke-width='1.5'/%3E%3Cpath d='M10 1.5V11.5' stroke='%230E306C' stroke-width='1.5'/%3E%3Cpath d='M2.5 11.293V16.624H17.5127V11.293' stroke='%230E306C' stroke-width='1.5'/%3E%3C/svg%3E");
$mobileAddToCartHeight: 169px;

:root {
    --add-to-cart-height: 70px;
}

$mobileAddToCartHeight: 169px;

.ProductActions {
    display: flex;
    width: 100%;

    @include mobile {
        flex-direction: column;
        margin-block-end: $mobileAddToCartHeight;
    }

    &-ProductPageWrapper {
        background-color: var(--color-white);

        @include scrollbar();

        @include desktop {
            display: grid;
            grid-template-columns: 69fr 31fr;
            grid-template-rows: repeat(2, auto) 1fr;
            grid-template-areas: "trim trim" "gallery info";
            row-gap: 0;
            padding-block-start: 40px;
            max-height: calc(100vh - var(--header-height));
            background-color: white;
            overflow-y: scroll;
            overflow-x: hidden;
            width: calc(100vw - #{$leftMenuWidth});
            margin-block-end: var(--add-to-cart-height) + 2px;

            &_currentStep {
                &_INSURANCE_STEP {
                    grid-template-columns: 50fr 50fr;
                }
            }
        }

        & > *:not(:last-child) {
            @include mobile {
                margin-block-end: 36px;
                margin-inline: 16px;
            }
        }
    }

    &-ProductStepsWrapper {
        border-right: 1px solid #D9D9D9;

        @include scrollbar();

        @include desktop {
            width: $leftMenuWidth;
            max-height: calc(100vh - var(--header-height));
            overflow-y: scroll;
            overflow-x: hidden;
        }

        &_isPrerendered {
            @include mobile {
                width: 100%;
            }
        }
    }

    &-Information {
        padding-inline: 11% 45px;
        border-inline-start: 1px dashed var(--disabled-color);

        & > *:not(:last-child) {
            margin-block-end: 48px;
        }

        @include desktop {
            grid-area: info;
            margin-block-end: 32px;
        }
    }

    &-Features {
        @include mobile {
            order: 3;
            padding: 36px 16px 16px 16px;
        }

        h2 {
            margin-block: 0 12px;
        }

        p, li {
            @include mobile {
                font-size: 16px;
                line-height: 24px;
            }
        }

        li {
            list-style-type: unset;

            &::before {
                display: none;
            }
        }
    }

    &-Title {
        margin: 0;
        font-size: 22px;
        font-weight: 700;

        @include desktop {
            margin-block: 13px;
            padding-inline-end: 24px;
            border-inline-end: 1px dashed var(--disabled-color);
            font-size: 30px;
            line-height: 32px;
            text-align: center;
        }
    }

    &-BottomInformation {
        padding: 12px 16px;

        @include desktop {
            display: flex;
            align-items: center;
            gap: 24px;
            padding-block: 0;
            padding-inline: 32px;
        }

        @include tablet {
            padding-inline: 16px;
        }

        @include iPadMini {
            padding-inline: 16px;
        }
    }

    .ProductPrice-PriceValue {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: var(--secondary-orange-color);
        margin-block-start: 0;

        @include desktop {
            font-size: 22px;
            line-height: 28px;
        }
    }

    &-PriceWrapper {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        margin-block-start: 4px;

        @include desktop {
            display: block;
        }

        .ProductActions-PriceWrapper {
            margin-block-start: 0;

            @include desktop {
                height: unset;
                margin-block: 0;
            }
        }
    }

    &-PriceContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include wide-desktop {
            flex-direction: row;
            align-items: flex-end;
        }
    }

    &-PricePerMonth {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        @include wide-desktop {
            margin-inline-start: 5px;
        }
    }

    &-Price {
        align-items: baseline;

        .ProductPrice-HighPrice {
            @include mobile {
                order: 1;
                margin-inline-start: 5px;
            }
        }
    }

    &-TotalAmountText {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        vertical-align: top;
        margin-inline-end: 8px;

        span:first-child {
            display: none;

            @include desktop {
                display: inline-flex;
            }
        }
    }

    &-AddToCartWrapper {
        grid-area: addToCartWrapper;
        position: fixed;
        inset-block-end: 0;
        display: grid;
        grid-template-columns: 3fr 2fr;
        margin-block-start: 0;
        background-color: var(--color-white);
        z-index: 90;

        @include desktop {
            width: calc(100vw - #{$leftMenuWidth});
            outline: 1px solid var(--stroke-color);
            min-height: var(--add-to-cart-height);

            &_isSummaryStep {
                width: 51vw;
                grid-template-columns: repeat(2, 1fr);

                .ProductActions {
                    &-BottomInformation {
                        padding: 4px 20px;
                    }

                    &-Schema {
                        width: 100%;
                    }

                    &-PriceWrapper {
                        margin: 0;
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                    }

                    &-PriceContainer {
                        flex-direction: column;
                    }

                    &-ActionButtons {
                        display: flex;
                    }
                }

                .ProductPrice {
                    &-PriceValue {
                        font-family: $font-HyundaiSansHeadKR;
                        font-size: 26px;
                        font-weight: 700;
                        line-height: 32px;
                    }

                    &-PriceBadge {
                        display: none;
                    }
                }

                .ChevronIcon {
                    display: none;
                }

                .Button {
                    width: 50%;
                    padding: 0;
                }
            }
        }

        @include tablet {
            grid-template-columns: repeat(2, 1fr);
        }

        @include iPadMini {
            grid-template-columns: repeat(2, 1fr);
        }

        .ProductActions-AddToCart,
        .ProductActions-AddToCart:hover {
            height: auto;
            margin-inline-end: 0;

            @include ultra-narrow-desktop {
                margin-block-start: 0;
            }
        }

        .Button,
        .Button:hover {
            height: auto;
            margin-inline-end: 0;
            padding-inline: 16px;

            @include ultra-narrow-desktop {
                margin-block-start: 0;
            }

            & svg {
                flex-shrink: 0;
            }
        }
    }

    &-AddToCartFixed {
        position: fixed;
        display: grid;
        align-items: center;
        grid-template-areas: '. .' 'addToCart addToCart';
        padding: 0;
        inset-block-end: 0;

        @include mobile {
            order: 10;
        }

        .hideOnScroll & {
            inset-block-end: 0;
            transform: none;
        }

        .ProductActions-SummaryButton {
            justify-self: end;
            padding: 0;
            width: 60px;
            height: 45px;
            border-inline-start: 1px dashed var(--disabled-color);
        }

        .ProductActions-AddToCart {
            grid-area: addToCart;
        }

        &_isSummaryStep {
            .ProductPrice-PriceValue {
                font-family: $font-HyundaiSansHeadKR;

                @include mobile {
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 28px;
                }
            }

            .ProductActions {
                &-PricePerMonth {
                    @include mobile {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }

                &-TotalAmoutText {
                    @include mobile {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }

                &-AddToCart {
                    display: flex;
                }
            }

            .Button {
                width: 50%;
                padding: 0;
            }

            .ChevronIcon {
                display: none;
            }
        }
    }

    &-ActionButtons {
        display: grid;
        grid-template-columns: 2fr 3fr;
        height: 100%;
    }

    &-SummaryButton {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-inline-start: 1px solid var(--stroke-color);
        padding-inline: 16px;

        svg {
            @include desktop {
                margin-inline-end: 7px;
            }
        }

        span {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            display: none;

            @include desktop {
                display: flex;
            }
        }
    }

    .ProductConfigurableAttributes-SelectedOptionLabel {
        display: none;
    }

    &-AttributesWrapper {
        @include mobile {
            order: 1;
            margin-block-end: 0;
        }
    }

    &-ProductGalleryWrapper {
        @include mobile {
            order: 2;
            padding: 0 16px;
        }

        @include desktop {
            grid-area: gallery;
            margin: 0 6%;
        }

        @include tablet {
            margin-inline-end: 0;
        }

        @include iPadMini {
            margin-inline-end: 0;
        }

        .ProductGallery {
            margin: 0;
            height: 24vw;

            @include mobile {
                height: calc(100vh / 3);
            }
        }
    }

    &-BrochureDownload {
        @include mobile {
            order: 5;
            padding: 0 16px 32px 16px;
        }

        .Downloads {
            display: flex;
            align-items: center;

            .DownloadItem {
                margin-inline-end: 24px;
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                color: $default-primary-base-color;

                &:hover {
                    color: $default-primary-base-color;
                }

                .DownloadIcon {
                    background-image: $downloadIcon;
                    width: 20px;
                    height: 20px;
                    margin-inline-start: 7px;
                }
            }
        }
    }

    &-FilteredAttributesWrapper {
        @include mobile {
            order: 4;
            padding: 0 16px 9px 16px;
        }
    }

    .BuildAnotherButton {
        background-color: var(--negative-color);
        border-color: var(--negative-color);

        &:hover {
            background-color: var(--negative-color-hover);
            border-color: var(--negative-color-hover);
        }
    }

    .GalleryTrim {
        grid-area: trim;
        font-family: $font-HyundaiSansHeadKR;
        color: black;
        font-weight: 700;
        font-size: 40px;
        line-height: 52px;
        margin-inline-start: 32px;
        margin-block-end: 2.85%;

        @include mobile {
            display: none;
        }
    }

    &-TextPlaceholder {
        display: block;
        margin-block-end: 1px;
    }
}
