/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

$arrowButtonSize: 28px;
$distanceBetweenArrowButtonsOnDesktop: 12px;
$buttonsPaddingRight: 52px;

:root {
    --gallery-interior-slider-height: calc(100vh - (var(--header-height) + var(--add-to-cart-height) + var(--breadcrumbs-height)));
}

@mixin chevronIconAsBackground {
    content: '';
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    width: 10px;
    height: 16px;
    background-image: $thinChevronIcon;
}

[dir="rtl"] .ProductGallery_isInterior.ProductGallery .Slider-Arrow {
    transform: translateY(-50%);

    &_isPrev {
        &::after {
            transform: rotate(180deg) translateX(-1px);
        }
    }

    &_isNext {
        &::after {
            transform: translateX(-1px);
        }
    }
}


.ProductGallery {
    @include narrow-desktop {
        min-width: unset;
    }

    @include mobile {
        margin: 0;
    }

    &_isTransitionOff {
        .Slider {
            &-Wrapper {
                transition-duration: unset;
            }
        }
    }

    &_isInterior.ProductGallery {
        @include desktop {
            max-height: unset;
        }

        .ProductGallery {
            &-SliderWrapper {
                @include desktop {
                    height: unset;
                }
            }

            &-Slider.Slider {
                @include desktop {
                    height: var(--gallery-interior-slider-height);
                }

                img {
                    @include desktop {
                        max-height: var(--gallery-interior-slider-height);
                    }
                }
            }
        }

        .Image-Image {
            object-fit: cover;
        }

        .Slider-Arrow {
            inset-block-start: unset;
            inset-block-end: 0;
            background-color: $white;
            width: $arrowButtonSize;
            height: $arrowButtonSize;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            &_isPrev {
                inset-inline-start: 16px;

                &::after {
                    @include chevronIconAsBackground;

                    transform: translateX(-1px);
                }

                @include desktop {
                    inset-inline-start: calc(100% - #{$buttonsPaddingRight} - #{$distanceBetweenArrowButtonsOnDesktop} - 2 * #{$arrowButtonSize});
                }
            }

            &_isNext {
                inset-inline-end: 16px;

                &::after {
                    @include chevronIconAsBackground;

                    transform: rotate(180deg) translateX(-1px);
                }

                @include desktop {
                    inset-inline-start: calc(100% - #{$buttonsPaddingRight} - #{$arrowButtonSize});
                }
            }

            &_isDisabled {
                background-color: var(--disabled-color);
            }

            .ChevronIcon {
                display: none;
            }

            @include desktop {
                inset-block-end: 63px;
            }
        }
    }
}
