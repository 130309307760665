/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

$sidePadding: 16px;

.ProductSummaryStep {
    width: 100%;

    @include desktop {
        position: fixed;
        height: calc(100vh - var(--header-height));
        inset-block-start: var(--header-height);
        overflow-y: scroll;
        overflow-x: hidden;
    }

    @include mobile {
        overflow: hidden;
    }

    @include scrollbar;

    &-LeftWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--surface-color);
        width: 50%;
        height: 100%;
        position: fixed;
        inset-block-start: var(--header-height);
        z-index: 1;

        @include mobile {
            position: relative;
            width: 100%;
            height: auto;
            inset-block-start: 0;
            padding: 48px $sidePadding 28px;
        }
    }

    &-RightWrapper {
        background-color: $white;
        width: 50%;
        height: auto;
        min-height: 100%;
        position: relative;
        inset-inline-start: 50%;
        z-index: 0;

        @include mobile {
            position: relative;
            width: 100%;
            inset-inline-start: 0;
            padding: 40px $sidePadding 0;
        }
    }

    &-InfoBlock {
        padding: 16% 20% 120px;
        margin-block-end: $addToCartHeight;

        .ProductAttributes {
            &-AttributeLabel {
                padding-inline-start: 2px;

                @include mobile {
                    padding-inline-start: 0;
                }
            }
        }

        @include mobile {
            padding: 0;
            margin: 0;

            &_isIos {
                .ProductAttributes {
                    &-AttributeLabel {
                        padding-inline-start: 6px;
                    }
                }
            }
        }
    }

    &-Image {
        margin-block-end: 12px;

        @include desktop {
            transform: translateX(9%);

            [dir=rtl] & {
                transform: translateX(-9%);
            }
        }
    }

    &-SubHeader {
        font-family: $font-HyundaiSansTextKR;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--disabled-color);
        margin-block-start: 0;
        margin-block-end: 4px;

        @include mobile {
            margin: 0 0 4px;
        }
    }

    &-Header {
        font-family: $font-HyundaiSansHeadKR;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        color: $black;
        margin-block-end: 0;
    }
}
