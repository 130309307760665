/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.RelatedProductTotals {
    margin: 0;

    &-Items,
    &-ItemsCount,
    &-TotalsWrapper {
        background-color: var(--default-primary-light-grey-color);
        padding: 16px;
        border: none;

        @include desktop {
            padding: 20px 24px;
        }
    }

    &-ItemsCount {
        border-bottom: 1px solid var(--color-white);

        @include desktop {
            padding: 0 24px 20px;
        }

        .Count {
            color: var(--default-primary-silver-color);
            font-size: 14px;
            line-height: 20px;

            span {
                font-weight: 400;
                color: var(--default-primary-silver-color);
            }
        }
    }

    &-Items {
        border-block-end: 1px solid var(--color-white);
    }

    &-Item {
        &:first-of-type {
            margin: 0;
        }

        .RelatedProductTotals {
            &-Name {
                .ItemName {
                    color: var(--default-primary-silver-color);
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    &-DeleteBtn {
        margin-block-start: 3px;

        .CloseIcon {
            path {
                stroke: var(--default-primary-silver-color);
            }
        }
    }

    &-Totals {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        color: var(--default-primary-silver-color);
        margin: 0;

        tbody {
            tr {
                &.RelatedProductTotals {
                    &-VehiclePrice,
                    &-UpgradesPrice {
                        border-block-end: none;
                    }
                }
            }
        }

        td {
            padding: 0;
        }

        .RelatedProductTotals {
            &-UpgradesPrice {
                &_isActive {
                    font-weight: 700;
                }

                td {
                    padding: 16px 0 0;
                }
            }
        }
    }

    &-TotalsPrice {
        text-align: end;
    }
}
