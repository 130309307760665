/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

$tooltipArrow: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.4 1.13333C11.2 0.0666666 12.8 0.0666668 13.6 1.13333L24 15H0L10.4 1.13333Z' fill='%23F4F5F7' stroke='%23CBD5DC' /%3E%3C/svg%3E%0A");

.ProductMonthlyPrice {
    &-PriceWrapper {
        .ToolTipButton {
            &-Icon {
                margin-inline-start: 6px;
                margin-block-start: 2px;
            }

            @include desktop {
                .Popup {
                    &_isVisible {
                        inset-inline-end: 5px;
                        inset-inline-start: unset;

                        html[dir="ltr"] & {
                            inset-inline-start: 5px;
                        }

                        html[dir="rtl"] & {
                            inset-inline-end: 5px;
                        }
                    }
                }
            }
        }
    }

    .ToolTipButton {
        &-Icon {
            cursor: default;
        }
    }

    .ToolTip {
        &-Popup {
            &.Popup {
                &_isVisible {
                    @include desktop {
                        transform: translateX(-50%);
                        margin-inline-start: 16px;
                        inset-block-start: 100%;
                        width: 320px;
                    }

                    .Popup {
                        &-Content {
                            height: initial;
                        }
                    }
                }

                .Popup {
                    &-Content {
                        border-radius: 6px;
                        border: 1px solid var(--default-primary-grey-color);
                        background-color: var(--default-primary-light-grey-color);
                        box-shadow: none;
                        padding: 12px 16px;

                        &::before {
                            inset-inline-start: 50%;
                            border: none;
                            background-image: $tooltipArrow;
                            height: 14px;
                            width: 24px;
                            border-width: 17px;
                            margin-inline-start: -17px;
                        }

                        &::after {
                            content: none;
                        }

                        &_direction {
                            &_bottom {
                                inset-block-start: 15px;

                                &::before {
                                    inset-block-start: -14px;
                                }
                            }

                            &_top {
                                inset-block-start: -4px;

                                @include desktop {
                                    inset-block-start: -108px;
                                    inset-block-end: 0;
                                }

                                &::before {
                                    transform: rotate(180deg);
                                }
                            }

                            &_top_left {
                                inset-block-start: -4px;

                                @include desktop {
                                    inset-block-start: -108px;
                                    inset-block-end: 0;
                                    inset-inline-end: 120px;
                                }

                                &::before {
                                    transform: rotate(180deg);
                                    inset-block-end: -14px;
                                    inset-inline-start: unset;
                                    inset-inline-end: 32px;
                                }
                            }
                        }
                    }

                    &-TopWrapper {
                        margin-block-end: 26px;
                    }

                    &-Header {
                        background-color: var(--default-primary-light-grey-color);
                    }
                }
            }

            .MonthlyPaymentTooltip {
                &-Wrapper {
                    margin-block-end: 26px;

                    @include desktop {
                        margin-block-end: 0;
                    }
                }
            }
        }
    }
}

.MonthlyPaymentTooltip {
    &-Wrapper {
        background: none;
        padding: 0;
        width: initial;
        height: initial;
    }

    &-Item {
        margin-block-end: 4px;

        &:last-of-type {
            margin-block-end: 0;
        }
    }

    &-Label,
    &-Value {
        font-size: 14px;
        line-height: 20px;
        color: var(--default-primary-dark-grey-color);
        font-family: var(--font-family-sans-pro);
    }

    &-Value {
        font-weight: 700;
    }
}
