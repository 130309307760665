/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

$selectedMark: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='10' fill='%23002D96'/%3E%3Cpath d='M5.875 10L8.5 12.625L14.125 7' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E%0A");

.ProductSteps {
    &-Compare {
        width: 100%;
        padding: 16px 0 0;

        &:hover {
            text-decoration: none;

            &.Button {
                padding: 16px 0 0;
            }

            .ProductSteps {
                &-CompareButtonText {
                    color: var(--link-color);
                }
            }

            .CompareIcon {
                path {
                    stroke: var(--link-color);
                }
            }
        }

        .CompareIcon {
            width: 24px;
            height: 24px;
            margin: 0 0 2px;

            path {
                will-change: stroke;
                transition: 300ms stroke;
            }
        }
    }

    &-CompareButtonText {
        margin-inline-start: 8px;
        font-weight: 700;
        font-family: var(--font-family-sans-pro);
        font-size: 16px;
        line-height: 24px;
        color: var(--default-primary-blue-color);
        will-change: color;
        transition: 300ms color;
    }

    &-Section {
        border-bottom: 1px solid var(--color-white);
        padding: 16px;
        width: 100%;

        @include desktop {
            padding: 20px 24px;
        }

        &.ExpandableContent {
            border-block-start: none;
            border-color: var(--color-white);
        }

        .ExpandableContent {
            &-Content {
                background: none;
                border: none;

                &_isContentExpanded {
                    margin: 12px 0 0;
                }
            }

            &-Button {
                padding: 0;
            }

            &-Heading {
                font-family: var(--font-family-sans-pro);
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-transform: uppercase;
            }
        }

        .Swatch {
            margin: 0;
            padding: 0;

            &-Header {
                margin: 0 0 12px;
                width: 100%;
            }

            &-Label {
                font-family: var(--font-family-sans-pro);
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-transform: uppercase;
                flex-grow: 1;
            }

            &-Value {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                display: flex;
                transform: none;
            }

            .ProductConfigurableAttributes {
                &-SwatchList {
                    text-align: start;
                }
            }

            .ProductAttributeValue {
                &-Color,
                &-Image-Overlay {
                    border: none;
                    width: 36px;
                    height: 36px;
                    margin-inline-end: 10px;

                    &::after {
                        content: $selectedMark;
                        border: none;
                        transform: none;
                        inset-inline-start: initial;
                        inset-inline-end: 9px;
                        inset-block-start: -2px;
                        z-index: 1;
                    }

                    &_isLight {
                        border: 1px solid var(--default-secondary-grey-color);
                    }
                }
            }
        }
    }
}
