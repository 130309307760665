/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

$colorAttributeGradient: linear-gradient(135deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 90%);
$colorNotAvailable: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31 31L5 5' stroke='%23F4F5F7' stroke-width='5'/%3E%3Cpath d='M31 31L5 5' stroke='%23595757' stroke-width='1.5'/%3E%3C/svg%3E%0A");

.ProductAttributeValue {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;

    &_isNotAvailable {
        .ProductAttributeValue {
            &-Color,
            &-Image-Overlay {
                &::before {
                    content: none;
                }
            }

            &-ColorGradient,
            &-ImageColorOverlay {
                &::after {
                    content: $colorNotAvailable;
                }
            }
        }
    }

    &-Text {
        background: none;
        padding: 0;
        min-height: initial;

        .ProductAttributeValue-Text {
            .input-control {
                margin-block-end: 0;
                margin-inline-end: 12px;

                &:first-of-type {
                    margin-block-start: 1px;
                }
            }

            input {
                visibility: hidden;
            }
        }
    }

    &-ColorGradient,
    &-ImageColorOverlay {
        position: absolute;
        height: 36px;
        width: 36px;
        inset-inline-start: 0;
        inset-block-end: 3px;
    }

    &-ColorGradient {
        background: $colorAttributeGradient;
    }

    .Field {
        &-CheckboxLabel {
            span {
                color: var(--default-primary-silver-color);
                font-size: 14px;
                line-height: 20px;
            }
        }

        &_type {
            &_checkbox {
                margin: 14px 0 0;
            }
        }
    }

    &-SubLabel {
        padding: 0;
        margin: 0 0 0 4px;
        font-weight: 400;
        color: var(--default-secondary-grey-color);
        font-size: 14px;
    }

    &-Image-Overlay {
        inset-block-start: unset;
    }
}
