/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

:root {
    --option-column-gap: 32px;
    --option-items-per-row: 4;
}

.ProductMainViewOptions {
    min-height: initial;

    &-ItemCount {
        padding: 16px;
        border-block-end: 1px solid var(--color-white);
        color: var(--default-primary-silver-color);

        @include mobile {
            display: flex;
            align-items: center;
            padding-inline: 16px;
            min-height: 48px;
        }
    }

    .ProductLinks {
        background-color: var(--default-primary-light-grey-color);
        margin: 0;
        padding: 16px;

        @include desktop {
            padding: 0;
            max-width: unset;
            width: 100%;
            margin: 0;
        }

        &-Wrapper {
            @include desktop {
                padding: 28px 0 36px;
            }
        }

        &-List {
            display: flex;
            flex-direction: column;
            gap: 16px;

            @include desktop {
                display: grid;
                grid-template-columns: repeat(4, calc((100% - (var(--option-items-per-row) - 1) * var(--option-column-gap)) / var(--option-items-per-row)));
                row-gap: var(--option-column-gap);
                gap: var(--option-column-gap);
            }
        }

        li {
            &.ProductCard {
                padding: 0;
                cursor: default;
                background: none;
                margin: 0;
                max-width: initial;

                @include desktop {
                    width: auto;
                }

                &:hover {
                    @include desktop {
                        box-shadow: 0px 2px 16px 0px #0A0A0B1F;

                        .RelatedProductCard {
                            &-Name {
                                color: var(--default-primary-blue-color);
                            }

                            &-SelectedText {
                                opacity: 1;
                            }
                        }
                    }
                }

                .input-control {
                    width: 18px;
                    height: 18px;
                    min-width: initial;
                    min-height: initial;
                }

                &_isAdded {
                    box-shadow: none;

                    .Field {
                        .input-control {
                            background-color: var(--default-primary-blue-color);
                            border-color: var(--default-primary-blue-color);
                        }
                    }
                }

                .ProductPrice {
                    &-PriceValue {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--default-primary-blue-color);
                    }
                }
            }
        }
    }
}
