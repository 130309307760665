/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

:root {
    //change ONLY this value if gallery size has to be adjusted
    --gallery-column-width-value: 0.60;
    --gallery-column-width: calc(var(--gallery-column-width-value) * 100%);
    --column-gap: 32px;
    --left-view-width: 73vw;
    --product-title-height: 120px;
    --add-to-cart-height: 122px;

    @include desktop {
        --add-to-cart-height: 106px;
    }
}

.ProductActions {
    flex-direction: column;
    margin-block-end: calc(var(--add-to-cart-height) + 4px);

    @include desktop {
        margin-block-end: 0;
    }

    @include mobile {
        background-color: var(--default-primary-light-grey-color);
    }

    &_isPrerendered {
        @include mobile {
            margin: 0;
        }
    }

    &_step {
        &_TRIM_STEP {
            .ProgressBar {
                @include desktop {
                    border-radius: 6px;
                }
            }
        }

        &_SUMMARY_STEP {
            /* stylelint-disable-next-line number-max-precision */
            margin: 20px 0 calc(var(--summary-mobile-add-to-cart-height) + 1.2px);

            @include desktop {
                margin: 0;
            }

            .ChevronIcon {
                display: block;
            }
        }
    }

    .AddToCart {
        padding : 12px 16px;

        &:not([disabled]):hover {
            @include mobile {
                line-height: normal;
            }
        }
    }

    &-ProductTitleWrapper {
        display: flex;
        justify-content: flex-start;
        background-color: var(--default-secondary-white-color);

        @include desktop {
            padding: 40px 40px 32px 40px;
            height: var(--product-title-height);
        }

        @include mobile {
            padding: 20px 16px 24px 16px;
            max-height: 112px;
        }

        h1 {
            font-weight: 500;
            font-size: 36px;
            line-height: 48px;
            font-family: var(--font-family-sans-pro);

            @include desktop {
                margin-inline-end: 12px;
                font-size: 36px;
                line-height: 48px;
            }

            @include mobile {
                margin-inline-end: 4px;
                font-size: 28px;
                line-height: 34px;
            }
        }

        p {
            color: var(--default-secondary-gold-color);
            font-weight: 500;
            font-family: var(--font-family-sans-pro);
            text-transform: uppercase;

            @include desktop {
                font-size: 20px;
                line-height: 24px;
                padding-block-start: 6px;
            }

            @include mobile {
                font-size: 16px;
                line-height: 24px;
                padding-block-start: 0;
            }
        }
    }

    &-SectionsWrapper {
        display: flex;
        width: 100%;
        height: calc(100% - var(--product-title-height) - 4px);

        &_isPrerendered {
            @include mobile {
                flex-direction: column;
                height: auto;
            }
        }
    }

    &-ProductPageWrapper {
        height: var(--product-page-main-height);
        width: var(--left-view-width);
        background-color: var(--default-primary-light-grey-color);
        border-radius: 0px $borderRadius $borderRadius 0px;
        display: flex;
        padding: 4px 40px;
        margin-block-end: 70px;
        order: 0;
        overflow-y: scroll;
        gap: var(--column-gap);

        @include scrollbar;

        &_isPrerendered {
            @include mobile {
                flex-direction: column;
                width: unset;
                padding: 0;
                height: auto;
                margin-block-end: 0;
            }

            .ProductActions {
                &-GalleryColWrapper {
                    @include mobile {
                        width: unset;
                        margin: 0;
                        margin-inline-end: 0;
                    }
                }

                &-InfoColWrapper {
                    @include mobile {
                        width: unset;
                        padding: 0;
                    }
                }
            }
        }
    }

    &-AttributesWrapper {
        order: initial;

        @include desktop {
            height: 100%;
        }

        .ProductConfigurableAttributes {
            &-Wrapper {
                border-radius: 6px;
                border: none;
                width: initial;
                height: 100%;

                @include desktop {
                    max-width: $leftMenuWidth;
                }
            }
        }
    }

    &-GalleryColWrapper {
        display: flex;
        flex-direction: column;
        width: var(--gallery-column-width);
    }

    &-InfoColWrapper {
        padding: 28px 0 0 0;
        display: flex;
        flex-direction: column;
        width: calc(100% - var(--column-gap) - var(--gallery-column-width));
    }

    &-Features {
        @include mobile {
            padding: 16px 16px 0 16px;
        }

        h2 {
            color: var(--default-primary-blue-color);
            font-size: 24px;
            font-weight: 500;
            line-height: 32px;
            text-transform: uppercase;
            margin-block-end: 8px;
        }

        p, ul li {
            font-family: var(--font-family-sans-pro);
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: var(--default-primary-silver-color);
            margin-block-end: 8px;
        }

        ul li {
            list-style: square;

            @include desktop {
                margin-inline-start: 15px;
            }

            @include mobile {
                margin-inline-start: 25px;
            }

            &::marker {
                color: var(--default-primary-blue-color);
            }
        }

        ul li:last-child, p:last-child, div:last-child, span:last-child, h1:last-child, h4:last-child, h3:last-child, h2:last-child {
            margin-block-end: 24px;
        }
    }

    &-Specifications {
        @include mobile {
            order: 4;
            padding: 0 16px 0 16px;
        }
    }

    &-EstimatedDelivery {
        @include mobile {
            order: 5;
            padding: 24px 16px 16px;
        }

        @include desktop {
            padding-block-start: 24px;
        }
    }

    &-BrochureDownload {
        @include mobile {
            order: 6;
            padding: 8px 16px 8px 16px;
        }

        @include desktop {
            padding: 30px 50px 30px 50px;
        }

        .Downloads {
            display: flex;

            @include desktop {
                align-items: center;
            }

            @include mobile {
                flex-direction: column;
                align-items: flex-start;
            }

            .DownloadItem {
                margin-inline-end: 75px;
                display: flex;
                align-items: center;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: var(--default-primary-blue-color);
                cursor: pointer;

                @include mobile {
                    margin-block-end: 12px;
                }

                &:hover {
                    color: var(--default-primary-blue-color);
                }

                .DownloadIcon {
                    background-image: $downloadIconGeely;
                    background-position: center;
                    width: 25px;
                    min-width: 20px;
                    height: 25px;
                    margin-inline-start: 0;
                    margin-inline-end: 8px;
                }
            }
        }
    }

    &-ProductStepsWrapper {
        order: 1;
        max-height: var(--product-page-main-height);
        overflow: hidden;

        &_isPrerendered {
            @include mobile {
                max-height: none;
            }
        }
    }

    &-TotalAmountText {
        color: var(--default-primary-silver-color);
        margin-block-end: 2px;
        font-family: var(--font-family-sans-pro);

        span:first-child {
            display: inline-flex;
        }

        @include mobile {
            margin-inline-end: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }
    }

    &-PriceContainer {
        align-items: baseline;
        margin-block-end: 4px;

        @include mobile {
            margin-block-end: 8px;
            align-items: flex-start;
        }
    }

    &-PriceWrapper {
        margin: 0;
        font-family: var(--font-family-sans-pro);

        .ProductPrice {
            &-PriceValue {
                color: var(--default-primary-blue-color);
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;

                @include mobile {
                    margin-block-end: 2px;
                }
            }
        }

        @include mobile {
            flex-direction: column;
            align-items: start;
        }
    }

    &-PricePerMonth {
        color: var(--default-primary-black-color);
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-inline-start: 12px;

        @include mobile {
            margin-block-end: 2px;
            margin-inline-start: 0px;
        }
    }

    &-SpecialOffer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
        color: var(--default-primary-silver-color);
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;

        .CmsBlock {
            &-Wrapper {
                .SpecialOfferBlock {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 6px;
                    color: var(--default-primary-silver-color);
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }

    &-AddToCartWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-area: unset;
        width: 100vw;
        inset-inline-start: 0;
        min-height: var(--add-to-cart-height);
        background-color: var(--default-primary-light-grey-color);
        outline: none;
        padding: 0 40px;

        &_isPrerendered {
            @include mobile {
                z-index: 100;
                flex-direction: column;
                align-items: start;
                min-height: auto;
                padding: 12px 16px 16px
            }

            .ProductActions {
                &-SpecialOffer, &-PricePerMonth {
                    @include mobile {
                        display: none;
                    }
                }
            }
        }

        @include desktop {
            &_isSummaryStep {
                .ProductActions {
                    &-PriceWrapper {
                        display: flex;
                        flex-direction: column;
                    }

                    &-BottomInformation {
                        padding: 0;
                    }

                    &-PriceContainer {
                        flex-direction: row;
                        margin-block-end: 5px;

                        .ProductPrice {
                            &-PriceValue {
                                font-family: var(--font-family-sans-pro);
                            }
                        }
                    }

                    &-ActionButtons {
                        .BuildAnotherButton {
                            background-color: transparent;
                            border: 1px solid var(--default-primary-black-color);
                            color: var(--default-primary-black-color);
                            padding: 12px 16px 8px;

                            &:hover {
                                color: var(--default-secondary-white-color);
                                background-color: var(--default-primary-black-color);
                            }
                        }
                    }

                    &-PriceValue {
                        font-family: var(--font-family-sans-pro);
                        text-transform: uppercase;
                    }

                    &-SpecialOffer {
                        .CmsBlock-Wrapper {
                            div {
                                display: flex;
                                align-items: center;
                                gap: 6px;

                                span {
                                    margin-block-start: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &-BackStepTitle {
        text-transform: capitalize;
        margin-inline-start: 4px;
    }

    &-ActionButtons {
        display: flex;
        gap: 10px;

        .Button {
            width: auto;
            min-width: 160px;
            min-height: 42px;
            font-size: 16px;
            line-height: 20px;

            &_isHollow {
                &:hover {
                    * {
                        color: var(--color-white);
                    }
                }
            }
        }

        .AddToCart {
            &-BackButton {
                font-family: var(--font-family-sans-pro);
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: var(--default-primary-blue-color);
                padding: 12px 7px 8px;
                min-width: initial;

                &:focus {
                    text-decoration: none;
                    padding: 12px 7px 8px;
                }

                &:hover {
                    text-decoration: none;
                    padding: 12px 7px 8px;

                    span {
                        color: var(--hover-color);
                    }

                    .ChevronIcon {
                        path {
                            stroke: var(--hover-color);
                        }
                    }
                }

                span {
                    color: var(--default-primary-blue-color);
                    will-change: color;
                    transition: 250ms color;
                }

                .ChevronIcon {
                    width: 12px;
                    height: 12px;
                    margin-inline-end: 8px;

                    path {
                        stroke: var(--default-primary-blue-color);
                    }
                }
            }
        }
    }

    &-BottomInformation {
        padding: 0;
        margin: 12px 0  17px;

        @include mobile {
            margin: 0;
        }
    }

    &-AddToCartFixed {
        padding: 12px 16px 16px;
        min-height: var(--add-to-cart-height);
        background-color: var(--default-primary-light-grey-color);
        border-top: none;

        .Button {
            min-height: 40px;

            svg {
                margin-inline-start: 9px;
            }

            &:hover {
                min-height: 40px;
            }
        }

        .AddToCart {
            &-Summary,
            &-Summary:hover {
                border: none;
                min-width: auto;
                width: auto;
                justify-self: end;
                color: var(--default-primary-black-color);
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                padding: 0;
            }
        }

        .ProductPrice {
            &-PriceValue {
                line-height: 24px;
                font-size: 20px;
            }
        }

        @include mobile {
            &_isSummaryStep {
                width: 100%;
                border-radius: 0;
                padding: 16px;
                height: var(--summary-mobile-add-to-cart-height);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;

                .ProductActions {
                    &-BottomInformation {
                        padding: 0;
                        margin: 0;
                        justify-content: space-between;
                        gap: unset;
                        width: auto;
                        display: flex;
                    }

                    &-AddToCart {
                        width: 100%;
                        gap: 12px;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column-reverse;

                        button {
                            height: 40px;
                            width: 100%;
                            min-height: unset;
                            min-width: unset;
                        }

                        .BuildAnotherButton {
                            background-color: transparent;
                            border-color: var(--default-primary-black-color);
                            color: var(--default-primary-black-color);
                            border: 1px solid;
                            padding: 12px 24px 8px 24px;

                            &:hover {
                                height: 40px;
                                background-color: var(--default-primary-black-color);
                                color: var(--default-secondary-white-color);
                            }
                        }
                    }

                    &-PriceWrapper {
                        align-items: flex-end;
                    }

                    &-PriceContainer {
                        flex-direction: column;
                        align-items: flex-end;
                        margin-block-end: 0;
                        justify-content: center;
                        gap: 0;
                    }
                }

                .ProductMonthlyPrice {
                    font-size: 16px;
                    text-transform: uppercase;
                    font-weight: 500;
                }

                .ProductPrice {
                    &-PriceValue {
                        font-family: var(--font-family-sans-pro);
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 32px;
                    }
                }
            }
        }
    }
}
