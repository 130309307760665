/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

$plusSign: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.68988 4.304V3.184C7.68988 3.008 7.54588 2.928 7.36987 2.928H4.77788V0.335999C4.77788 0.16 4.69788 0.0159998 4.52188 0.0159998H3.40188C3.22588 0.0159998 3.14587 0.16 3.14587 0.335999V2.928H0.569875C0.393875 2.928 0.249875 3.008 0.249875 3.184V4.304C0.249875 4.48 0.393875 4.56 0.569875 4.56H3.14587V7.136C3.14587 7.312 3.22588 7.456 3.40188 7.456H4.52188C4.69788 7.456 4.77788 7.312 4.77788 7.136V4.56H7.36987C7.54588 4.56 7.68988 4.48 7.68988 4.304Z' fill='%23595757'/%3E%3C/svg%3E%0A");
$checkMark: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.875 6L4.5 8.625L10.125 3' stroke='%23595757' stroke-width='2'/%3E%3C/svg%3E ");

.RelatedProductCard {
    &-FigureReview {
        padding: 4px 4px 1px;
        background-color: var(--color-white);
        border-radius: 6px 6px 0 0;

        .Image,
        img {
            border-radius: 2px;
        }

        .Image {
            // 3:2
            padding-block-end: 63%;
        }

        .Field {
            &_type {
                &_radio {
                    margin: 0;
                    line-height: normal;
                }
            }

            &-Wrapper {
                inset-block-start: 8px;
                inset-inline-start: 8px;
            }
        }
    }

    &-Name {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        font-family: var(--font-family-sans-pro);
        display: block;
        margin: 0;
        white-space: initial;
        text-transform: uppercase;
        will-change: color;
        transition: 300ms color;
    }

    &-Content {
        padding: 0 12px;
        background-color: var(--color-white);
        margin: 0 0 1px;
        min-height: 126px;

        &_isAdded {
            .RelatedProductCard {
                &-Name {
                    color: var(--default-primary-blue-color);
                }
            }
        }
    }

    &-Description {
        margin: 4px 0 0;

        p {
            font-size: 12px;
            line-height: 18px;
            margin: 0;
            color: var(--default-primary-silver-color);
            min-height: initial;
            -webkit-line-clamp: initial;
            -webkit-box-orient: initial;
        }
    }

    &-BottomBlock {
        background-color: var(--color-white);
        display: flex;
        border-radius: 0 0 6px 6px;
        padding: 10px 12px 6px;
        flex-wrap: wrap;

        .ProductPrice {
            min-height: initial;
        }

        .CardPriceWrapper {
            margin: -2px 0 0;
            min-width: min-content;

            span {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: var(--default-primary-blue-color);
                font-family: var(--font-family-sans-pro);
                display: inline-block;
            }
        }
    }

    &-PriceWrapper {
        display: inline-block;
    }

    &-SelectedText {
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        color: var(--default-primary-silver-color);
        flex-grow: 1;
        min-width: min-content;

        @include desktop {
            opacity: 0;
            will-change: opacity;
            transition: 200ms opacity;
        }

        &::before {
            content: $plusSign;
            margin-inline-end: 6px;
        }

        &_isAdded {
            opacity: 1;

            &::before {
                content: $checkMark;
            }
        }
    }
}
