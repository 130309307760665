/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

/* stylelint-disable declaration-no-important */

:root {
    --quotation-header-height: 146px;
    --product-header-height: 192px;
    --summary-image-width: 45vw;
    --summary-image-col-padding-left: 32px;
    --summary-image-col-padding-right: 40px;
    --summary-image-col-width: calc(var(--summary-image-col-padding-left) + var(--summary-image-col-padding-right) + var(--summary-image-width));
    --summary-add-to-cart-height: 168px;
    --summary-mobile-side-padding: 16px;
    --summary-mobile-add-to-cart-height: 250px;
}

.ProductSummaryStep {
    display: flex;
    flex-direction: column;
    position: relative;
    inset-block-start: 0;
    overflow-y: hidden;
    margin-block-end: 0;
    height: auto;

    /* START Heading Style */

    &-QuotationHeaderWrapper {
        @include desktop {
            height: var(--quotation-header-height);
            margin-block-start: 0;
            padding: 32px 40px;
        }

        @include mobile {
            padding: var(--summary-mobile-side-padding);
        }

        display: flex;
        flex-direction: column;
        background-color: var(--default-primary-light-grey-color);
    }

    &-QuotationMainHeader {
        @include desktop {
            font-size: 36px;
            line-height: 48px;
        }

        @include mobile {
            font-size: 20px;
            line-height: 24px;
        }

        margin-block-end: 10px;
        color: var(--default-primary-black-color);
        text-transform: uppercase;
        font-weight: 500;
        font-family: var(--font-family-sans-pro);
    }

    &-QuotationSubText {
        font-size: 16px;
        line-height: 24px;
        margin-block-end: 0;
        color: var(--default-primary-black-color);
        text-transform: none;
        font-weight: 400;
        font-family: var(--font-family-sans-pro);
    }

    &-ProductTitleWrapper {
        @include desktop {
            height: var(--product-header-height);
            width: 100%;
            padding: 80px 40px 32px 40px;
        }

        @include mobile {
            width: 100%;
            padding: 24px var(--summary-mobile-side-padding);
        }

        background-color: var(--default-secondary-white-color);
    }

    .ProductAttributes {
        &-TitleContainer {
            @include desktop {
                width: 100%;
                margin-block-end: 8px !important;
            }

            background-color: var(--default-secondary-white-color);
            justify-content: flex-start;
            padding: 0;
        }

        &-AttributeHeader {
            @include desktop {
                padding: 32px 40px;
                margin-block-end: 4px;
            }

            @include mobile {
                border-radius: 0;
            }
        }

        &-AttributeHeaderPrice {
            @include desktop {
                margin-block-start: 32px;
            }
        }

        &-ProductTitle {
            @include desktop {
                font-size: 36px;
                line-height: 48px;
            }

            @include mobile {
                font-size: 28px;
                line-height: 34px;
            }

            width: unset;
        }

        &-ProductTrim {
            @include desktop {
                font-size: 20px;
                line-height: 24px;
                margin-block-start: 6px;
            }

            @include mobile {
                font-size: 16px;
                line-height: 24px;
                margin-block-start: 0;
            }
        }

        &-ValueLabel {
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            font-family: var(--font-family-sans-pro);
            color: var(--default-primary-silver-color);

            @include mobile {
                margin-block-end: 16px;
            }
        }

        &-AttributeLabel {
            @include mobile {
                margin-block-end: 16px;
            }
        }
    }

    &-ProductTitleSubtext {
        font-size: 16px;
        line-height: 24px;
        margin-block-end: 0;
        color: var(--default-primary-silver-color);
        text-transform: none;
        font-weight: 400;
        font-family: var(--font-family-sans-pro);
    }
    /* END Heading Style */

    /* START Content Wrapper Style */

    &-ContentWrapper {
        display: flex;

        @include mobile {
            flex-direction: column-reverse;
        }
    }

    &-InfoColumn {
        @include desktop {
            width: calc(100% - var(--summary-image-col-width));
        }

        @include mobile {
            width: 100%;
        }

        .ProductActions {
            &-AddToCartFixed {
                &_isSummaryStep {
                    @include mobile {
                        min-height: var(--summary-mobile-add-to-cart-height);
                    }
                }
            }

            &-TotalAmountText {
                @include mobile {
                    margin-block-end: 6px;
                }
            }

            &-PriceContainer {
                .ProductPrice {
                    &-PriceValue {
                        @include mobile {
                            margin-block-end: 6px;
                        }
                    }
                }
            }
        }
    }

    &-InfoBlock {
        padding: 0;
        margin-block-end: 0;
    }

    &-ImageColumn {
        @include desktop {
            width: var(--summary-image-col-width);
            padding: 0 var(--summary-image-col-padding-right) 0 var(--summary-image-col-padding-left);

            .Image {
                width: var(--summary-image-width);
            }
        }

        .Image {
            transform: none;
            margin-block-end: 0;
        }

        @include mobile {
            width: 100%;
        }
    }
    /* END Content Wrapper Style */
}
