/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

.ProductList {
    &-LoadMoreButtonWrapper {
        margin-block: 48px 0;

        @include mobile {
            padding-inline: 16px;
        }
    }

    &-LoadMoreButton {
        margin-block-end: 48px;
        width: 100%;
        font-size: 22px;

        @include desktop {
            width: 348px;
            margin-block-end: 0;
        }

        &:focus {
            color: var(--color-white);
        }
    }
}
