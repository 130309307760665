/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

:root {
    --product-card-background: var(--default-primary-light-grey-color);
}

.ProductCard {
    border-radius: 6px;
    background-color: var(--product-card-background);
    will-change: box-shadow;
    transition: box-shadow 300ms;

    @include tabletAndWider {
        max-width: 490px;
    }

    &:hover {
        @include desktop {
            box-shadow: 0px 4px 14px rgba(10, 10, 11, 0.1);
        }

        .ProductCard {
            &-ImageBlock {
                &_isMultipleImages {
                    .ProductCard-Picture {
                        opacity: 0;
                    }

                    .ProductCard-SecondPicture {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .ProductCard-Link {
        cursor: default;
    }

    &-Picture {
        // 16:9
        padding-block-end: 56.25%;
        will-change: opacity;
        transition: opacity 200ms;

        @include tabletAndWider {
            padding-block-end: 50%;
        }
    }

    &-SecondPicture {
        position: absolute;
        inset: 0;
        opacity: 0;
        will-change: opacity;
        transition: opacity 200ms;
    }

    &-FigureReview {
        padding: 4px 4px 0;
        background: none;
    }

    &-Figure {
        background-color: rgba(203, 213, 220, 0.2);
        border-radius: 2px;
    }

    &-Content {
        background: none;
        padding: 12px;

        @include tabletAndWider {
            padding: 20px 20px 12px;
        }
    }

    &-Name {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        font-family: var(--font-family-sans-pro);
        max-width: fit-content;

        @include tabletAndWider {
            font-size: 24px;
            line-height: 32px;
        }
    }

    &-ShortDescription,
    &-Name,
    &-ShortDescription p {
        @include tabletAndWider {
            display: inline;
        }
    }

    &-ShortDescription {
        @include tabletAndWider {
            margin-inline-start: 6px;
        }
    }

    &-ShortDescription p {
        font-size: 14px;
        line-height: 20px;
        color: var(--default-primary-silver-color);
        white-space: nowrap;
    }

    &-Info {
        height: initial;
        display: flex;
        flex-direction: column;

        @include tabletAndWider {
            flex-direction: row;
        }
    }

    &-PriceWrapper {
        border-block-start: 1px solid var(--default-primary-grey-color);
        padding: 9px 0 0;
        flex-grow: 1;
        margin: 0 0 8px;

        @include tabletAndWider {
            margin: 0;
            padding: 0;
            border-block-start: none;
        }
    }

    &-BottomBlock {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include tabletAndWider {
            margin: 4px 0 0;
            flex-direction: row;
            align-items: center;
        }
    }

    .AddToCart {
        padding: 12px 28px;
        font-family: var(--font-family-sans-pro);
        font-size: 16px;
        line-height: 20px;

        @include tabletAndWider {
            max-width: 100px;
        }
    }

    .ProductPrice {
        &-Price {
            align-items: flex-start;

            @include tabletAndWider {
                flex-direction: column;
            }
        }

        &-PriceBadge {
            font-size: 14px;
            line-height: 20px;
            color: var(--default-primary-silver-color);
        }

        &-PriceValue {
            font-size: 20px;
            line-height: 24px;
            font-family: var(--font-family-sans-pro);
            font-weight: 500;
            color: var(--default-primary-blue-color);
            margin: 4px 0 0;

            @include tabletAndWider {
                margin: 0;
                font-size: 24px;
                line-height: 32px;
            }
        }
    }

    .amasty-label-text {
        font-size: 14px;
        line-height: 20px;
        padding: 6px 12px 4px;
        background-color: var(--default-primary-blue-color);
        border-radius: 6px;
        text-transform: uppercase;
        font-family: var(--font-family-sans-pro);
        font-weight: 500;
    }
}
