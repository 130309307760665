/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

.ProductPrice {
    min-height: 20px;

    @include desktop {
        min-height: 28px;
    }

    &-PriceBadge {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--disabled-color);
        margin-inline-end: 8px;
    }

    &-PriceValue {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;

        @include desktop {
            line-height: 24px;
        }
    }

    &-HighPrice {
        line-height: 20px;
    }

    del {
        font-weight: 400;
    }
}
