/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

.ProductMainViewInsurance {
    &-Information {
        order: 3;
    }
    
    &-AddInsuranceButtonWrapper {
        background: white;
        color: black;
        outline: 1px solid #d9d9d9;
        min-height: 60px;
        padding: 18px 16px;
        margin-block-end: 50px;
        cursor: pointer;

        @include desktop {
            width: 400px;
        }

        @include mobile {
            width: 100%;
        }

        &:hover {
            background: $default-primary-base-color;
            color: white;
            outline: none;

            span {
                color: white;
            }
        }

        &_active {
            background: $default-primary-base-color;
            color: white;
            outline: none;

            span {
                color: white;
            }
        }

        &_disabled {
            pointer-events: none;
            background: var(--disabled-color);
            color: white;
        }
    }

    &-AddInsuranceButtonText {
        font-weight: 700;
        line-height: 24px;
        font-size: 16px;
        text-align: center;
    }
}
