/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.ProductConfigurableAttributes {
    display: flex;
    flex-direction: column;
    row-gap: 1px;

    &-DropDownList {
        margin: 0;
    }

    &-SwatchList {
        display: block;
    }

    &-Wrapper {
        padding: 0;
        border: none;

        @include desktop {
            margin: 0 40px 0 32px;
        }

        &:first-of-type {
            border-radius: 6px 6px 0 0;
        }

        &:last-of-type {
            border-radius: 0 0 6px 6px;
        }
    }

    &-ExpandableContent {
        &Heading {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            font-family: var(--font-family-sans-pro);
            text-transform: uppercase;
            color: var(--default-primary-black-color);
            cursor: pointer;
        }

        &Content {
            display: none;

            &_isContentExpanded {
                display: block;
            }
        }
    }

    .PriceSlider {
        padding: 0;
        border: none;

        &-Wrapper {
            .PriceSlider {
                &-PriceRange {
                    margin-block-end: 0;
                }

                &-Slider {
                    margin-block-start: 20px;
                }

                &-Slider,
                &-SliderProgress {
                    margin-block-end: 32px;
                }
            }
        }

        &-SliderProgress {
            background: var(--default-primary-blue-color);
        }

        &-Range {
            &Min,
            &Max {
                &::-webkit-slider-thumb {
                    height: 20px;
                    width: 20px;
                    background: var(--default-primary-blue-color);
                    margin: -2px 0 0;
                    box-shadow: none;
                    border: none;
                }

                &::-moz-range-thumb {
                    height: 20px;
                    width: 20px;
                    background: var(--default-primary-blue-color);
                    margin: -2px 0 0;
                    box-shadow: none;
                    border: none;
                }
            }
        }

        &-MinPriceMark,
        &-MaxPriceMark {
            inset-block-start: 14px;
            color: var(--default-primary-silver-color);
            font-size: 14px;
            line-height: 20px;
            transform: none;
        }

        &-Label {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            font-family: var(--font-family-sans-pro);
            text-transform: uppercase;
            margin: 0;
            color: var(--default-primary-black-color);
        }

        &-PriceRange {
            font-size: 14px;
            line-height: 20px;
            color: var(--default-primary-black-color);
        }

        &-PriceRangeText {
            color: var(--default-primary-silver-color);
        }

        &-Slider {
            margin-inline-end: 0;
            margin-inline-start: 0;
            width: 100%;
        }
    }

    .ProductConfigurableAttributes {
        &-Wrapper {
            padding: 20px 24px;
            width: initial;
        }

        &-ExpandableContentHeading {
            &.ExpandableContent {
                &-Heading {
                    margin-block-end: 0;
                }
            }
        }
    }
}
