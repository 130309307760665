/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

.ProductStepsTab {
    &-PopupButton {
        width: initial;
        white-space: nowrap;
        margin-inline-end: 16px;
    }

    &-Tab {
        padding: 0;
        overflow-y: auto;
        overflow-x: hidden;

        @include scrollbar();

        &_active {
            border: none;
        }

        &_stepCode {
            &_TRIM_STEP {
                background-color: var(--default-primary-light-grey-color);
                border-radius: 0 0 $borderRadius $borderRadius;

                @include desktop {
                    margin: 4px 0 0;
                    border-radius: 6px;
                }
            }
        }
    }

    &-TabHeader {
        padding: 24px 0 0;
        height: initial;
        border: none;
        background-color: var(--color-white);

        .TabHeaderText {
            font-family: var(--font-family-sans-pro);
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            color: var(--default-primary-black-color);
            margin: 0 0 0 2px;
        }

        .ChevronIcon {
            margin-inline-end: 0;
            margin-block-end: 2px;
        }
    }

    &-TabHeaderTextWrapper {
        flex-direction: row;
        align-items: center;
        width: initial;
        margin-block: 0;
        margin-inline: 16px 0;
    }

    &-Select {
        width: 100%;
        line-height: 20px;

        &_isActive {
            background-color: var(--button-hover-background);
            border-color: var(--button-hover-border);
            color: var(--button-hover-color);
        }
    }

    &-SelectWrapper {
        row-gap: 8px;

        button {
            &:disabled {
                justify-content: flex-start;
                pointer-events: none;
            }
        }
    }

    &-OutOfStockLabel {
        position: absolute;
        inset-inline-end: 8px;
        inset-block-start: 7px;
        font-family: var(--font-family-sans-pro);
        font-weight: 500;
        color: var(--primary-error-color);
        text-transform: uppercase;
        font-size: 12px;
        line-height: 16px;
        padding: 6px 12px 2px;
        background-color: var(--color-white);
        border-radius: 100px;
    }
}
