/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

/* stylelint-disable declaration-no-important */
/* stylelint-disable number-max-precision */

:root {
    --gallery-interior-slider-height: calc(100vh - (var(--header-height) + var(--add-to-cart-height) + var(--breadcrumbs-height)));
    --gallery-slider-height: 342px;
    --additional-picture-height: 84px;
    --slider-bottom-gap: 4px;
    --total-gallery-height: calc(var(--additional-picture-height) + var(--slider-bottom-gap) + var(--gallery-slider-height));
    --gallery-height-mobile: 200px;
    --gallery-padding-mobile: 42px;
    --gallery-total-height-mobile: calc(var(--gallery-height-mobile) + var(--gallery-padding-mobile))
}

.ProductGallery {
    @include desktop {
        height: var(--total-gallery-height);
        margin: 0;
    }

    @include mobile {
        height: var(--gallery-total-height-mobile);
        border-bottom: var(--default-secondary-white-color) 1px solid;
    }

    &-SliderWrapper {
        @include desktop {
            height: var(--gallery-slider-height);
            border-radius: 6px;
        }

        .Slider {
            @include desktop {
                border-radius: 6px;
                height: var(--gallery-slider-height);
            }

            &-Arrow {
                display: none;
            }

            &-Wrapper {
                @include mobile {
                    height: var(--gallery-height-mobile);
                    background-color: var(--default-primary-light-grey-color);
                }
            }

            &-Crumbs {
                position: relative;
                height: var(--gallery-padding-mobile);
                inset-block-end: unset;
                background-color: var(--default-primary-light-grey-color);
            }

            &-Crumb {
                width: 10px;
                height: 10px;
                background-color: var(--default-secondary-grey-color);

                &_isActive {
                    background-color: var(--default-primary-blue-color);
                }
            }
        }

        .Image {
            background-color: var(--default-primary-light-grey-color);

            @include desktop {
                background-color: var(--default-secondary-white-color);

                img {
                    object-fit: contain;
                    object-position: center;
                }
            }
        }
    }

    &-GalleryZoomIcon {
        position: absolute;
        inset-block-start: 12px;
        inset-inline-end: 12px;
        z-index: 3;
        cursor: pointer;
        mix-blend-mode: difference;

        svg {
            path, circle {
                stroke: white;
            }
        }
    }
}

.ImageZoomPopup {
    background-color: transparent;

    .ProductGallery {
        &-SliderWrapper {
            width: 100%;

            .Slider {
                height: 100%;

                &-Arrow {
                    display: block;
                    position: fixed;
                    transform: scale(1.8);
                    inset-block-start: 42vh;

                    &_isNext {
                        inset-inline-end: 32px;
                    }

                    &_isPrev {
                        inset-inline-start: 32px;
                    }
                }
            }

            .Image {
                img {
                    object-fit: contain;
                    object-position: center;
                    height: 100% !important;
                }
            }
        }

        &-GalleryZoomIcon {
            display: none;
        }

        &-Additional {
            inset-block-end: calc((var(--additional-picture-height) + 6px) * (-1));
            inset-inline-start: 26%;

            &_children {
                &_1 {
                    inset-inline-start: 45%;
                }

                &_2 {
                    inset-inline-start: 38%;
                }

                &_3 {
                    inset-inline-start: 32%;
                }
            }

            .CarouselScrollArrow {
                inset-inline-start: calc((var(--arrow-position) + 8px + 10px) * (-1));
                inset-inline-end: unset;

                &_isNextArrow {
                    inset-inline-end: calc((var(--arrow-position) + 8px + 10px) * (-1));
                    inset-inline-start: unset;
                }
            }
        }
    }

    &-PopupContent {
        background: rgba(0, 0, 0, 0.84);

        .Popup {
            &-Header {
                @include desktop {
                    position: fixed;
                    background-color: transparent;
                    inset-inline-end: 0;
                    padding: 32px;
                }
            }

            &-HeaderWrapper {
                @include desktop {
                    background-color: transparent;
                    padding: unset;
                    border-radius: 0 0 0 6px;
                }
            }

            &-ChildrenWrapper {
                padding: 32px 14vw 16vh 14vw;
            }

            &-CloseBtn {
                position: fixed;
                inset-block-start: 42px !important;
                inset-inline-end: 32px !important;
                width: 24px;
                height: 24px;

                &::after {
                    width: 24px;
                    height: 24px;
                    content: " ";
                    background-image: $zoomCloseIcon;
                    cursor: pointer;
                }

                svg {
                    display: none;
                }
            }
        }
    }
}
