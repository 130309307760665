/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

.ProductConfigurableAttributes {
    div:not(:last-child) {
        margin-block-end: 32px;

        @include mobile {
            margin-block-end: 36px;
        }
    }

    &-Title {
        margin-block: 0 20px;

        @include mobile {
            margin-block: 0 16px;
        }
    }

    &-SwatchList {
        margin: 0;
        padding-inline-start: 0;
        padding-block-start: 0;

        @include ultra-narrow-desktop {
            justify-content: center;
        }
    }
}
