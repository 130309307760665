/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/button.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/parts.override';
@import '../../style/abstract/icons.override';

/* stylelint-disable declaration-no-important */
$contentHeight: calc(var(--breadcrumbs-height) + var(--add-to-cart-height) + var(--product-title-height) + var(--product-page-first-height) - 22px);

.ProductPage {
    padding: 0;

    &_step_SUMMARY_STEP {
        > section {
            @include desktop {
                position: relative;
                height: auto !important;
                margin-block-end: calc(var(--add-to-cart-height) + 3px);
            }
        }
    }

    @include desktop {
        padding: var(--breadcrumbs-height) 0 0;
    }

    > section {
        @include desktop {
            height: calc(100vh - var(--header-total-height) - var(--breadcrumbs-height) - var(--add-to-cart-height));
        }
    }
}
