/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../geely/src/style/abstract/variables';
@import '../../../../geely/src/style/abstract/media';
@import '../../../../geely/src/style/abstract/button';
@import '../../../../geely/src/style/abstract/loader';
@import '../../../../geely/src/style/abstract/icons';
@import '../../../../geely/src/style/abstract/parts';
@import '../../../../geely/src/style/abstract/variables.override';
@import '../../../../geely/src/style/abstract/button.override';
@import '../../../../geely/src/style/abstract/media.override';
@import '../../../../geely/src/style/abstract/parts.override';
@import '../../../../geely/src/style/abstract/icons.override';

.ProductDownloadableLinks {
    &-Title {
        margin-block: 0 20px;

        @include mobile {
            display: block;
        }
    }

    &-List {
        display: flex;
        gap: 24px;
    }
}

.ProductDownloadableLink {
    padding: 0;
    border: none;

    &:first-of-type {
        @include mobile {
            padding-block-start: 0;
        }
    }

    &:hover {
        @include desktop {
            .DownloadIcon {
                stroke: var(--link-hover);
            }
        }
    }

    &-SampleLink {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-inline-start: 0;

        @include mobile {
            margin-inline-end: 0;
        }

        &::before {
            content: none;
        }
    }

    .DownloadIcon {
        stroke: var(--primary-base-color);
    }
}
